import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/opt/buildhome/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_eTVJQYlCmx from "/opt/buildhome/repo/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import _0__32vue_toastifications_3zpJDWPCq1 from "/opt/buildhome/repo/plugins/0. vue-toastifications.ts";
import _1__32store_code_client_TJ6XH8hgOl from "/opt/buildhome/repo/plugins/1. store-code.client.ts";
import _2__32customer_token_client_8FCOBx7k47 from "/opt/buildhome/repo/plugins/2. customer-token.client.ts";
import _2__32nuxt_i18n_y3ZLyovpBB from "/opt/buildhome/repo/plugins/2. nuxt-i18n.ts";
import _3__32get_cart_client_00YEsVHcAa from "/opt/buildhome/repo/plugins/3. get-cart.client.ts";
import _4__32validations_GS03hFKbuW from "/opt/buildhome/repo/plugins/4. validations.ts";
import address_helper_vgVHfuXVas from "/opt/buildhome/repo/plugins/address-helper.ts";
import format_price_3WLkrmGvIB from "/opt/buildhome/repo/plugins/format-price.ts";
import swatch_helper_mh5FwkNw7I from "/opt/buildhome/repo/plugins/swatch-helper.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_eTVJQYlCmx,
  _0__32vue_toastifications_3zpJDWPCq1,
  _1__32store_code_client_TJ6XH8hgOl,
  _2__32customer_token_client_8FCOBx7k47,
  _2__32nuxt_i18n_y3ZLyovpBB,
  _3__32get_cart_client_00YEsVHcAa,
  _4__32validations_GS03hFKbuW,
  address_helper_vgVHfuXVas,
  format_price_3WLkrmGvIB,
  swatch_helper_mh5FwkNw7I
]